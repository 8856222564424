import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import {
  CheckCircle as CheckCirleIcon,
  DoDisturb as DoDisturbIcon,
  Edit as EditIcon,
  SupervisorAccount as EditAdminOfIcon,
} from "@mui/icons-material";
import {
  Button,
  List,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useRequireMe } from "hooks/Me";
import { DateTime } from "luxon";
import type { MRT_ColumnDef } from "material-react-table";
import { NN } from "types";

import { IconButtonWithText } from "components/common/buttons";
import DeletePopover from "components/common/DeletePopover";
import Table, { sortListBy } from "components/common/MRTable";
import { TeamChip } from "components/common/TeamChip";

import {
  ActivateButton,
  ArchiveButton,
  DeleteButton,
  EditButton,
} from "../common/AdminTableIconButtons";

import type { User, UsersTable_fragment$key as Key } from "./types";

const TABLE_CONFIG_KEY = "admin-users-table-config";

const fragment = graphql`
  fragment UsersTable_fragment on UserNode @relay(plural: true) {
    id
    fullName
    username
    isActive
    archivedAt
    email
    firstName
    lastName
    employmentDegree
    employmentForm
    employmentTitle
    competences {
      edges {
        node {
          id
          name
        }
      }
    }
    timebankBaseBalance
    userSetting {
      id
    }
    groupMemberOf {
      edges {
        node {
          id
          name
        }
      }
    }
    memberOf {
      edges {
        node {
          id
          name
          color
        }
      }
    }
    teamAdminOf {
      edges {
        node {
          id
        }
      }
    }
    groupAdminOf {
      edges {
        node {
          id
        }
      }
    }
  }
`;

type ItemType = NonNullable<User>;
type ColumnType = MRT_ColumnDef<ItemType>;

type Team = NN<NN<NN<User>["memberOf"]>["edges"]>[number];
type TeamGroup = NN<NN<NN<User>["groupMemberOf"]>["edges"]>[number];

type ActionsType = {
  onEdit: () => void;
  onEditAdminOf: () => void;
  onArchive: () => void;
  onActivate: () => void;
  onDelete: () => void;
};

type ActionsProps = ActionsType & {
  userId: string;
  userIsActive: boolean;
  userFullName: string;
};

type TooltipTableCellProps = {
  label: string;
  tooltipTitle: string;
  items: readonly any[];
  renderItem: (item: any) => React.ReactNode;
};

type Props = {
  fragmentRef: Key;
  onEdit: (id: string) => void;
  onEditAdminOf: (id: string) => void;
  onArchive: (id: string) => void;
  onActivate: (id: string) => void;
  onDelete: (user: User) => void;
  onEditMulti: (ids: string[]) => void;
};

function TooltipTag({
  label,
  tooltipTitle,
  items,
  renderItem,
}: TooltipTableCellProps) {
  if (!items?.length) {
    return <Typography>{label}</Typography>;
  }
  return (
    <Tooltip
      arrow
      followCursor
      title={
        <Stack sx={{ p: 0.5 }}>
          <Typography sx={{ m: 0, py: 0.5 }} variant="h5" fontWeight={700}>
            {tooltipTitle}
          </Typography>
          <List sx={{ p: 0, m: 0 }}>
            {items.map((item) => (
              <ListItemText
                key={item?.node?.id}
                primaryTypographyProps={{ variant: "body2" }}
              >
                {`\u2022`}&nbsp;&nbsp;{renderItem(item)}
              </ListItemText>
            ))}
          </List>
        </Stack>
      }
    >
      <Stack gap={0.5} direction="row">
        {items.map((item) => (
          <TeamChip team={item.node} key={item?.node?.id} size="small" />
        ))}
      </Stack>
    </Tooltip>
  );
}

function Actions({
  userId,
  userIsActive,
  userFullName,
  onEdit,
  onEditAdminOf,
  onActivate,
  onArchive,
  onDelete,
}: ActionsProps) {
  const { userData: me } = useRequireMe();

  const { id } = me || {};
  return (
    <Stack direction="row" justifyContent="flex-end">
      <EditButton onClick={() => onEdit()} />
      <Tooltip title={`Sätt avdelningar ${userFullName} är admin över`}>
        <IconButtonWithText
          text="Admin"
          icon={<EditAdminOfIcon color="info" />}
          onClick={() => onEditAdminOf()}
        />
      </Tooltip>
      {userIsActive ? (
        <DeletePopover
          onDeleteClick={() => onArchive()}
          deleteMessage={`Är du säker på att du vill inaktivera användare ${userFullName}?`}
          buttonText="Inaktivera"
          disabled={id === userId}
        >
          <ArchiveButton
            onClick={(e) => e.preventDefault()}
            disabled={id === userId}
          />
        </DeletePopover>
      ) : (
        <ActivateButton onClick={() => onActivate()} />
      )}
      <DeleteButton onClick={() => onDelete()} disabled={id === userId} />
    </Stack>
  );
}

export default function UsersTable({
  fragmentRef,
  onEdit,
  onEditAdminOf,
  onArchive,
  onActivate,
  onDelete,
  onEditMulti,
}: Props) {
  const items = useFragment<Key>(fragment, fragmentRef);
  const columns = useMemo<ColumnType[]>(
    () => [
      { accessorKey: "id", header: "ID", enableGrouping: false },
      {
        accessorKey: "isActive",
        header: "Aktiv",
        enableGrouping: false,
        accessorFn: (user) =>
          user?.isActive ? (
            <CheckCirleIcon color="primary" sx={{ p: "2px" }} />
          ) : (
            <DoDisturbIcon color="disabled" sx={{ p: "2px" }} />
          ),
        aggregationFn: "count",
        AggregatedCell: ({ cell }) => `${cell.getValue()} aktiva`,
        Footer: ({ table }) => {
          const activeCount =
            table
              .getFilteredRowModel()
              .flatRows.filter((row) => row.original.isActive).length || 0;
          return <Stack>{activeCount} aktiva</Stack>;
        },
      },
      {
        accessorKey: "archivedAt",
        header: "Tid för arkivering",
        accessorFn: (c) =>
          c?.archivedAt ? DateTime.fromISO(c.archivedAt).toFormat("F") : "-",
      },
      {
        accessorKey: "fullName",
        header: "Namn",
        enableGrouping: false,
        aggregationFn: "count",
        AggregatedCell: ({ cell }) => `${cell.getValue()} personer`,
        Footer: ({ table }) => {
          const peopleCount = table.getFilteredRowModel().flatRows.length || 0;
          return <Stack>{peopleCount} personer</Stack>;
        },
      },
      {
        accessorKey: "username",
        header: "Användarnamn",
        enableGrouping: false,
      },
      { accessorKey: "email", header: "E-post", enableGrouping: false },
      {
        header: "Enheter",
        id: "groups",
        accessorFn: (user) =>
          (user?.groupMemberOf?.edges || []).map((x) => x?.node?.name),
        filterFn: (row, _id, filterValue) =>
          (row.original?.groupMemberOf?.edges || []).some((group) =>
            group?.node?.name
              ?.toLowerCase()
              .includes(filterValue.toLowerCase()),
          ),
        sortingFn: (a, b) =>
          sortListBy<TeamGroup>(
            (a.original?.groupMemberOf?.edges || []) as TeamGroup[],
            (b.original?.groupMemberOf?.edges || []) as TeamGroup[],
            (x) => x?.node?.name || "",
          ),
        enableGrouping: false,
        Cell: ({ cell }) => {
          const user = cell.row.original;
          const groups = user?.groupMemberOf?.edges || [];
          const isAdmin = (groupId: string | null) =>
            (user.groupAdminOf?.edges || []).some(
              (e) => e?.node?.id === groupId,
            ) || false;
          return (
            <TooltipTag
              label={`${groups.length || "Inga"} enheter`}
              tooltipTitle="Enheter"
              items={groups}
              renderItem={(group) =>
                `${group?.node?.name}${isAdmin(group?.node?.id) ? " *" : ""}`
              }
            />
          );
        },
      },
      {
        header: "Avdelningar",
        enableGrouping: false,
        id: "teams",
        accessorFn: (user) =>
          (user?.memberOf?.edges || []).map((x) => x?.node?.name),
        filterFn: (row, _id, filterValue) => {
          const teams = row.original?.memberOf?.edges || [];
          return teams.some((team) =>
            team?.node?.name?.toLowerCase().includes(filterValue.toLowerCase()),
          );
        },
        sortingFn: (a, b) =>
          sortListBy<Team>(
            (a.original?.memberOf?.edges || []) as Team[],
            (b.original?.memberOf?.edges || []) as Team[],
            (x) => x?.node?.name || "",
          ),
        Cell: ({ cell }) => {
          const user = cell.row.original;
          const teams = user?.memberOf?.edges || [];
          const isAdmin = (groupId: string | null) =>
            (user.teamAdminOf?.edges || []).some(
              (e) => e?.node?.id === groupId,
            ) || false;
          return (
            <TooltipTag
              label={`${teams.length || "Inga"} avdelningar`}
              tooltipTitle="Avdelningar"
              items={teams}
              renderItem={(team) =>
                `${team?.node?.name}${isAdmin(team?.node?.id) ? " *" : ""}`
              }
            />
          );
        },
      },
      {
        header: "Åtgärder",
        enableGrouping: false,
        accessorFn: (user) => (
          <Actions
            userId={user.id}
            userIsActive={user.isActive}
            userFullName={user.fullName}
            onEdit={() => user.id && onEdit(user.id)}
            onEditAdminOf={() => user.id && onEditAdminOf(user.id)}
            onActivate={() => user.id && onActivate(user.id)}
            onArchive={() => user.id && onArchive(user.id)}
            onDelete={() => user.id && onDelete(user)}
          />
        ),
      },
    ],
    [onEdit, onEditAdminOf, onActivate, onArchive, onDelete],
  );
  const data = useMemo<ItemType[]>(() => (items || []) as ItemType[], [items]);

  return (
    <Table
      tableConfigKey={TABLE_CONFIG_KEY}
      columns={columns as any[]}
      data={data}
      enableRowSelection
      initialState={{
        sorting: [
          { id: "groups", desc: true },
          { id: "teams", desc: true },
          { id: "fullName", desc: false },
        ],
      }}
      renderTopToolbarCustomActions={({ table }) => {
        const editMultiDisabled =
          !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected();

        function onClick() {
          const ids: string[] = table
            .getSelectedRowModel()
            .flatRows.map((row) => row.getValue("id"));
          onEditMulti(ids);
        }

        return (
          <Button
            onClick={onClick}
            disabled={editMultiDisabled}
            variant="primary"
            startIcon={<EditIcon />}
          >
            Editera valda användare
          </Button>
        );
      }}
    />
  );
}
