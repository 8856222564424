/**
 * @generated SignedSource<<c89a99cb71564ab4e6ca16f6c8498392>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResultsScheduleWarningsContextQuery$variables = {
  scheduleId: string;
};
export type ResultsScheduleWarningsContextQuery$data = {
  readonly scheduleValidationWarnings: {
    readonly " $fragmentSpreads": FragmentRefs<"ScheduleWarningsDrawer_fragment" | "WarningsDrawerButton_fragment">;
  };
};
export type ResultsScheduleWarningsContextQuery = {
  response: ResultsScheduleWarningsContextQuery$data;
  variables: ResultsScheduleWarningsContextQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scheduleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "scheduleId",
    "variableName": "scheduleId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResultsScheduleWarningsContextQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleValidationWarnings",
        "kind": "LinkedField",
        "name": "scheduleValidationWarnings",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScheduleWarningsDrawer_fragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WarningsDrawerButton_fragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResultsScheduleWarningsContextQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScheduleValidationWarnings",
        "kind": "LinkedField",
        "name": "scheduleValidationWarnings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ValidationResult",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ValidationError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "module",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "message",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityNode",
                    "kind": "LinkedField",
                    "name": "activity",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "start",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "end",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserSnapshotNode",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hardError",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6eddbdcb8428b7e93142fd5ddd9f0139",
    "id": null,
    "metadata": {},
    "name": "ResultsScheduleWarningsContextQuery",
    "operationKind": "query",
    "text": "query ResultsScheduleWarningsContextQuery(\n  $scheduleId: ID!\n) {\n  scheduleValidationWarnings(scheduleId: $scheduleId) {\n    ...ScheduleWarningsDrawer_fragment\n    ...WarningsDrawerButton_fragment\n  }\n}\n\nfragment ScheduleWarningsDrawer_fragment on ScheduleValidationWarnings {\n  viewer {\n    errors {\n      module\n      message\n      activity {\n        start\n        end\n        id\n      }\n      user {\n        id\n        name\n      }\n      hardError\n    }\n  }\n}\n\nfragment WarningsDrawerButton_fragment on ScheduleValidationWarnings {\n  viewer {\n    errors {\n      hardError\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "72d17d094b022e9478357787a8c4c30b";

export default node;
