import axios from "axios";
import { API_BASE_URL } from "utils/constants";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true, // If you need to include credentials in every request
  // Other default configurations can be added here
});

export default axiosInstance;
