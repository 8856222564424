import { ChangeEvent, Suspense, useCallback, useEffect, useMemo } from "react";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormGroup,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, useField, useFormikContext } from "formik";
import { useCurrentTeamGroup, useFormikState } from "hooks";
import { DateTime } from "luxon";
import * as yup from "yup";

import { DialogTitle } from "components/common/Dialog";
import BlockedTimeSlotTypeSelect from "components/common/selectors/BlockedTimeSlotTypeSelect";
import { UserSelect } from "components/common/selectors/UserSelect";
import { SmallSwitch } from "components/common/Switch";
import DatePickerWithWeekNumbers from "components/schedule/DatePickerWithWeekNumbers";

import { BlockedTimeSlotShiftSelect } from "../BlockedTimeSlotShiftSelect";
import {
  AddExdateToBlockedTimeSlot,
  DeleteBlockedTimeSlot,
} from "../mutations";
import {
  BlockedTimeSlotFormValues as FormValues,
  BlockedTimeSlotType,
} from "../types";

import { BlockedTimeSlotFormQuery } from "./__generated__/BlockedTimeSlotFormQuery.graphql";
import { RecurrenceFormik } from "./Recurrence";

const shiftTypeQuery = graphql`
  query BlockedTimeSlotFormQuery($teamGroupId: ID!) {
    shiftTypes(teamGroupId: $teamGroupId) {
      ...BlockedTimeSlotShiftSelect_fragment
    }
  }
`;

export const validationSchema = yup.object({
  start: yup.date().when("timeslotType", {
    is: BlockedTimeSlotType.FIXED_SHIFT,
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.required("Fyll i starttid"),
  }),
  end: yup.date().when("timeslotType", {
    is: BlockedTimeSlotType.FIXED_SHIFT,
    then: (schema) => schema.nullable(),
    otherwise: (schema) =>
      schema
        .required("Fyll i sluttid")
        .test(
          "is-greater",
          "Sluttid måste vara efter starttid",
          function (endDate) {
            const startDate = this.parent.start;
            if (startDate && endDate) {
              return new Date(endDate) > new Date(startDate);
            }

            return true;
          },
        ),
  }),
  userIds: yup.array(yup.string()).min(1).required("Fyll i minst en person"),
  shiftId: yup.string().when("timeslotType", {
    is: BlockedTimeSlotType.FIXED_SHIFT,
    then: (schema) => schema.required("Välj ett pass"),
    otherwise: (schema) => schema.nullable(),
  }),
  shiftDate: yup.date().when("timeslotType", {
    is: BlockedTimeSlotType.FIXED_SHIFT,
    then: (schema) => schema.required("Fyll i passdatum"),
    otherwise: (schema) => schema.nullable(),
  }),
});

type DialogFooterProps = {
  id?: string;
  onClose: () => void;
  deletable?: boolean;
  submitText: string;
};

type Props = {
  title: any;
  submitText: any;
  modalProps: {
    open: boolean;
    onClose: () => void;
  };
  deletable?: boolean;
  id?: string;
};

function FormikSwitch({ name, label }: { name: string; label: string }) {
  const { value, setValue } = useFormikState(name);
  function onChange(e: any) {
    setValue(e.target.checked);
  }
  return (
    <FormGroup>
      <Stack direction="row" gap={1}>
        <Typography>{label}</Typography>
        <Stack justifyContent="center">
          <SmallSwitch checked={value} onChange={onChange} />
        </Stack>
      </Stack>
    </FormGroup>
  );
}

function DialogFooter({
  id,
  onClose,
  deletable,
  submitText,
}: DialogFooterProps) {
  const { values, isSubmitting, dirty } = useFormikContext<FormValues>();
  const isRecurrence: boolean = !!values.originalTimeslotId;
  const submitDisabled = !!isSubmitting && !dirty;

  function onDeleteClick() {
    if (isRecurrence) {
      AddExdateToBlockedTimeSlot(
        values?.originalTimeslotId || "",
        DateTime.fromISO(values.start as string).toFormat("yyyy-MM-dd'T'T"),
      )
        .then(() => {
          onClose();
        })
        .catch(console.error);
    } else {
      DeleteBlockedTimeSlot(id || "").then(() => {
        onClose();
      });
    }
  }

  return (
    <Stack
      gap={0}
      direction="row"
      justifyContent={deletable ? "space-between" : "end"}
      alignItems="center"
      px={1}
      width="100%"
    >
      {deletable ? (
        <Button variant="delete" onClick={() => onDeleteClick()}>
          {isRecurrence ? "Radera upprepning" : "Radera händelse"}
        </Button>
      ) : null}
      <Stack direction="row" gap={1}>
        <Button variant="cancel" onClick={onClose}>
          Avbryt
        </Button>
        <Button variant="primary" type="submit" disabled={submitDisabled}>
          {submitText}
        </Button>
      </Stack>
    </Stack>
  );
}

function RecurrenceFormikSection({ name }: { name: string }) {
  const [, meta, helper] = useField(name);
  const { value } = meta;
  const { setValue } = helper;
  const checked: boolean = !!value;

  const handleChange = useCallback(
    (_e: ChangeEvent, b: boolean) => {
      if (b) {
        setValue([]);
      } else {
        setValue(null);
      }
    },
    [setValue],
  );

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1}>
        <Typography>Upprepas</Typography>
        <Stack justifyContent="center">
          <SmallSwitch checked={checked} onChange={handleChange} />
        </Stack>
      </Stack>
      {!!value && <RecurrenceFormik name={name} />}
    </Stack>
  );
}

function FormFields() {
  const teamGroup = useCurrentTeamGroup();
  const { setFieldValue } = useFormikContext<FormValues>();
  const { value: start } = useFormikState("start");
  const { value: end } = useFormikState("end");
  const { value: shift } = useFormikState("shiftDate");
  const dtformat = "yyyy-MM-dd'T'T";
  const dtformatOnlyDate = "yyyy-MM-dd";

  const startDate = useMemo(
    () => DateTime.fromJSDate(new Date(start)),
    [start],
  );
  const endDate = useMemo(() => DateTime.fromJSDate(new Date(end)), [end]);
  const shiftDate = useMemo(
    () => DateTime.fromJSDate(shift ? new Date(shift) : new Date()),
    [shift],
  );
  const { value: blockedTimeSlotType } = useFormikState("timeslotType");
  const { setValue: setBlockedTimeSlotShift } = useFormikState("shiftId");

  const [queryRef, loadQuery] =
    useQueryLoader<BlockedTimeSlotFormQuery>(shiftTypeQuery);
  useEffect(() => {
    loadQuery({ teamGroupId: teamGroup.id });
  }, [loadQuery, teamGroup.id]);

  useEffect(() => {
    if (blockedTimeSlotType !== BlockedTimeSlotType.FIXED_SHIFT) {
      setBlockedTimeSlotShift(undefined);
    }
  }, [blockedTimeSlotType, setBlockedTimeSlotShift]);

  const changeStart = useCallback(
    (newStart: DateTime | null) => {
      if (!newStart) {
        return;
      }

      setFieldValue("start", newStart.toFormat(dtformat));
      const startDt = DateTime.fromFormat(start, dtformat);
      if (!newStart.isValid) {
        return;
      }
      const endDt = DateTime.fromFormat(end, dtformat);
      const duration = endDt.diff(startDt, "minutes");
      const newEndDt = newStart.plus(duration);
      if (newEndDt.isValid) {
        setFieldValue("end", newEndDt.toFormat(dtformat));
      }
    },
    [start, end, setFieldValue],
  );

  const changeEnd = useCallback(
    (newEnd: DateTime | null) => {
      if (!newEnd) {
        return;
      }
      setFieldValue("end", newEnd.toFormat(dtformat));
    },
    [setFieldValue],
  );

  const changeFixed = useCallback(
    (newFixed: DateTime | null) => {
      if (!newFixed) {
        return;
      }
      setFieldValue("shiftDate", newFixed.toFormat(dtformatOnlyDate));
    },
    [setFieldValue],
  );

  return (
    <Grid container spacing={3} pt={1}>
      <Grid md={6}>
        <FormGroup>
          <UserSelect
            name="userIds"
            label="Personer"
            multi
            disableCloseOnSelect
            showSelectAll
            teamGroup={teamGroup}
          />
        </FormGroup>
      </Grid>
      <Grid md={6}>
        <FormGroup>
          <BlockedTimeSlotTypeSelect />
        </FormGroup>
      </Grid>
      {blockedTimeSlotType === BlockedTimeSlotType.FIXED_SHIFT ? (
        <>
          <Grid md={6}>
            <FormGroup>
              <Suspense fallback={"Laddar..."}>
                {!!queryRef && <ShiftSelectWrapper queryRef={queryRef} />}
              </Suspense>
            </FormGroup>
          </Grid>
          <Grid md={6}>
            <FormGroup>
              <DatePickerWithWeekNumbers
                label="Start"
                value={shiftDate}
                onChange={changeFixed}
                size="small"
                views={["year", "month", "day"]}
              />
            </FormGroup>
          </Grid>
        </>
      ) : (
        <>
          <Grid md={6}>
            <FormGroup>
              <DatePickerWithWeekNumbers
                label="Start"
                onChange={changeStart}
                value={startDate}
                size="small"
              />
            </FormGroup>
          </Grid>
          <Grid md={6}>
            <FormGroup>
              <DatePickerWithWeekNumbers
                label="Slut"
                onChange={changeEnd}
                value={endDate}
                size="small"
              />
            </FormGroup>
          </Grid>
        </>
      )}
      <Grid lg={4}>
        <FormikSwitch name="approvedByAdmin" label="Godkänd av teamledare" />
      </Grid>
      {blockedTimeSlotType !== BlockedTimeSlotType.FIXED_SHIFT && (
        <Grid xs={12}>
          <RecurrenceFormikSection name="recurrences" />
        </Grid>
      )}
    </Grid>
  );
}

type ShiftSelectWrapperProps = {
  queryRef: PreloadedQuery<BlockedTimeSlotFormQuery>;
};
function ShiftSelectWrapper({ queryRef }: ShiftSelectWrapperProps) {
  const data = usePreloadedQuery<BlockedTimeSlotFormQuery>(
    shiftTypeQuery,
    queryRef,
  );

  return (
    <Suspense fallback={null}>
      <BlockedTimeSlotShiftSelect fragmentRef={data.shiftTypes} />
    </Suspense>
  );
}

export default function BlockedTimeSlotForm({
  title,
  id,
  deletable,
  submitText,
  modalProps,
}: Props) {
  return (
    <FormikForm autoComplete="off">
      <DialogTitle onClose={modalProps.onClose}>{title}</DialogTitle>
      <Divider />

      <DialogContent>
        <FormFields />
      </DialogContent>
      <Divider />

      <DialogActions>
        <DialogFooter
          id={id}
          onClose={modalProps.onClose}
          deletable={deletable}
          submitText={submitText}
        />
      </DialogActions>
    </FormikForm>
  );
}
