import { useMemo } from "react";
import { Stack, Tab, Tabs as MuiTabs } from "@mui/material";
import type { TabKey } from "hooks/query_params/useAdminParams";
import { useAdminParams } from "hooks/query_params/useAdminParams";

import TabPanel from "components/common/TabPanel";

import { AdminCompetences } from "./AdminCompetences";
import { AdminPermissions } from "./AdminPermissions";
import { AdminTeamGroups } from "./AdminTeamGroups";
import { AdminTeams } from "./AdminTeams";
import { AdminUsers } from "./AdminUsers";

const ADMIN_USER_LIST = 0;
const ADMIN_TEAMS = 1;
const ADMIN_TEAM_GROUPS = 2;
const ADMIN_COMPETENCES = 3;
const ADMIN_PERMISSIONS = 4;

function tabToIdx(hash: TabKey): number {
  switch (hash) {
    case "teams":
      return ADMIN_TEAMS;
    case "groups":
      return ADMIN_TEAM_GROUPS;
    case "competences":
      return ADMIN_COMPETENCES;
    case "permissions":
      return ADMIN_PERMISSIONS;
    case "users":
    default:
      return ADMIN_USER_LIST;
  }
}

function idxToTab(idx: number): TabKey {
  switch (idx) {
    case ADMIN_TEAMS:
      return "teams";
    case ADMIN_TEAM_GROUPS:
      return "groups";
    case ADMIN_COMPETENCES:
      return "competences";
    case ADMIN_PERMISSIONS:
      return "permissions";
    case ADMIN_USER_LIST:
    default:
      return "users";
  }
}

type TabHeaderProps = {
  idx: number;
  setIdx: (idx: number) => void;
};

type TabSectionProps = {
  idx: number;
};

function TabHeader({ idx, setIdx }: TabHeaderProps) {
  return (
    <MuiTabs
      value={idx}
      onChange={(_: unknown, idx: number) => setIdx(idx)}
      sx={{ pl: 1 }}
    >
      <Tab label="Användare" />
      <Tab label="Avdelningar" />
      <Tab label="Enheter" />
      <Tab label="Kompetenser" />
      <Tab label="Behörigheter" />
    </MuiTabs>
  );
}

function TabSection({ idx }: TabSectionProps) {
  return (
    <Stack>
      <TabPanel index={ADMIN_USER_LIST} value={idx}>
        <AdminUsers />
      </TabPanel>
      <TabPanel index={ADMIN_TEAM_GROUPS} value={idx}>
        <AdminTeamGroups />
      </TabPanel>
      <TabPanel index={ADMIN_TEAMS} value={idx}>
        <AdminTeams />
      </TabPanel>
      <TabPanel index={ADMIN_COMPETENCES} value={idx}>
        <AdminCompetences />
      </TabPanel>
      <TabPanel index={ADMIN_PERMISSIONS} value={idx}>
        <AdminPermissions />
      </TabPanel>
    </Stack>
  );
}

export function Tabs() {
  const { tab, setTab } = useAdminParams();
  const idx = useMemo(() => tabToIdx(tab), [tab]);
  return (
    <Stack>
      <TabHeader idx={idx} setIdx={(i: number) => setTab(idxToTab(i))} />
      <TabSection idx={idx} />
    </Stack>
  );
}
