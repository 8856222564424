import { useEffect, useMemo, useState } from "react";
import Timeline, {
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";
import { useFragment } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import {
  ArrowBackIos as GoBackIcon,
  ArrowForwardIos as GoForwardIcon,
} from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import useWindowSize from "hooks/useWindowSize";
import resizeDetector from "libs/react-calendar-timeline/container-resize-detector";
import { DateTime, Interval } from "luxon";
import type { Unwrap } from "relay-help/arrays";

import DeleteModal from "components/common/DeleteModal";
import CreatePeriod from "components/period/mutations/CreatePeriod";
import DeletePeriod from "components/period/mutations/DeletePeriod";
import DatePickerWithWeekNumbers from "components/schedule/DatePickerWithWeekNumbers";

import { TimelineItemRenderer } from "./TimelineItemRenderer";
import {
  PeriodTimeline_fragment$data as Data,
  PeriodTimeline_fragment$key as Key,
} from "./types";

const fragment = graphql`
  fragment PeriodTimeline_fragment on PeriodNode @relay(plural: true) {
    id
    name
    startDate
    endDate
    numberOfWeeks
    masterSchedule {
      id
    }
    schedules {
      edges {
        node {
          id
        }
      }
    }
  }
`;

type PeriodType = Unwrap<Data>;

const TIMELINE_ITEM_ZINDEX = 80;

const getNrPeriodsPerPage = (width: number) => {
  if (width < 500) {
    return 1.5;
  } else if (width < 1000) {
    return 3;
  } else {
    return 5;
  }
};

function CustomTodayMarker() {
  const markerZIndex = TIMELINE_ITEM_ZINDEX + 10;
  return (
    <TodayMarker date={Date.now()}>
      {({ styles }) => {
        const sx = {
          ...styles,
          bgcolor: "",
          zIndex: markerZIndex,
          borderWidth: 1,
          borderColor: "ture.100",
          borderStyle: "dashed",
        };
        return <Box sx={sx} />;
      }}
    </TodayMarker>
  );
}

type Props = {
  fragmentRef: Key | null;
  selected: string | null;
  currentDateState: [DateTime, (d: DateTime) => void];
  periodLength: number;
  refresh: () => Promise<void>;
};

export function PeriodTimeline({
  fragmentRef,
  selected,
  currentDateState,
  periodLength,
  refresh,
}: Props) {
  const navigate = useNavigate();
  const [showCreate, setShowCreate] = useState(false);
  const [toDelete, setToDelete] = useState<PeriodType | null>(null);
  const showDelete = Boolean(toDelete);
  const [currentDate, setCurrentDate] = currentDateState;
  const periods = useFragment<Key>(fragment, fragmentRef);

  const groups = [{ id: 1, title: "" }]; // Not shown

  let lastEndDate = useMemo(() => {
    if (!periods?.length) return DateTime.now().startOf("day").toJSDate();
    const lastDate = Math.max(
      ...(periods || []).map((p) => (p?.endDate ? Date.parse(p.endDate) : 0)),
    );
    if (!lastDate) {
      return DateTime.now().toJSDate();
    }

    return DateTime.fromMillis(lastDate).toJSDate();
  }, [periods]);

  const newPeriodBlock = useMemo(
    () => ({
      id: "new",
      group: 1,
      title: "Ny period",
      start_time: lastEndDate,
      end_time: DateTime.fromJSDate(lastEndDate)
        .plus({ weeks: periodLength })
        .minus({ days: 1 })
        .toJSDate(),
      nr_weeks: periodLength,
      master: false,
      onDelete: () => {},
      itemProps: {
        className: "create-new",
      },
    }),
    [lastEndDate, periodLength],
  );

  const items = useMemo(
    () => [
      ...(periods || []).map((p) => {
        const isSelected: boolean = selected === p?.id;
        const start = p?.startDate
          ? DateTime.fromISO(p.startDate)
          : DateTime.now();
        const end = p?.endDate ? DateTime.fromISO(p?.endDate) : DateTime.now();

        let periodStatusClassName = "currentPeriod";
        const now = DateTime.now();
        if (now < start) periodStatusClassName = "futurePeriod";
        else if (now > end) periodStatusClassName = "pastPeriod";

        const className = [
          periodStatusClassName,
          isSelected ? "selected" : null,
        ]
          .filter(Boolean)
          .join(" ");

        return {
          id: p?.id || "",
          group: 1,
          title: `Vecka ${p?.name}`,
          start_time: start.toJSDate(),
          end_time: end.minus({ days: 1 }).toJSDate(),
          nr_weeks: p?.numberOfWeeks,
          master: !!p?.masterSchedule?.id,
          schedules: p?.schedules.edges.length || 0,
          onDelete: () => setToDelete(p),
          itemProps: {
            className,
          },
        };
      }),
      newPeriodBlock,
    ],
    [periods, selected, newPeriodBlock],
  );

  const selectedIndex = useMemo(
    () => items.findIndex((i) => i.id === selected),
    [items, selected],
  );
  const firstSelected = useMemo(() => selectedIndex === 0, [selectedIndex]);
  const lastSelected = useMemo(
    () => selectedIndex === items.length - 1,
    [selectedIndex, items],
  );

  // zoom around selected period
  useEffect(() => {
    if (selectedIndex !== undefined) {
      const selectedItem = items[selectedIndex];
      if (!selectedItem) return;

      const diff = Interval.fromDateTimes(
        selectedItem.start_time,
        selectedItem.end_time,
      ).length();
      const centerDate = DateTime.fromJSDate(selectedItem.start_time).plus({
        milliseconds: diff / 2,
      });
      setCurrentDate(centerDate);
    }
  }, [selectedIndex, items, setCurrentDate]);

  // Set zoom properties
  const windowSize = useWindowSize();
  const windowLength = getNrPeriodsPerPage(windowSize.width) * periodLength;
  const start = currentDate
    .minus({
      weeks: windowLength / 2,
    })
    .toJSDate();
  const end = currentDate
    .plus({
      weeks: windowLength / 2,
    })
    .toJSDate();
  const zoom = (end.getTime() - start.getTime()) * 1000; // No zoom allowed

  function onItemSelect(itemId: string) {
    if (itemId === "new") {
      setShowCreate(true);
    } else {
      navigate(`/periods/${itemId}`);
    }
  }
  function onItemClick(itemId: string) {
    if (itemId === "new") {
      setShowCreate(true);
    }
  }

  function selectPrevPeriod() {
    if (firstSelected) return;
    const prevItem = items[selectedIndex - 1];
    if (!prevItem) return;
    onItemSelect(prevItem.id);
  }

  function selectNextPeriod() {
    if (lastSelected) return;
    const nextItem = items[selectedIndex + 1];
    if (!nextItem) return;
    onItemSelect(nextItem.id);
  }

  function setCurrentDateEvent(inputDate: DateTime | null) {
    setCurrentDate(inputDate ?? DateTime.fromJSDate(new Date()));
  }

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={2} alignItems="center" sx={{ pl: 4, py: 2 }}>
        <Box>
          <DatePickerWithWeekNumbers
            label="Gå till"
            value={currentDate}
            onChange={setCurrentDateEvent}
            views={["year", "month", "day"]}
          />
        </Box>
        <Stack direction="row" alignItems="center" gap={1}>
          <IconButton
            onClick={selectPrevPeriod}
            disabled={firstSelected}
            sx={{ p: 0 }}
          >
            <GoBackIcon fontSize="small" />
          </IconButton>
          <Typography variant="h4" sx={{ ml: -0.6 }}>
            Växla period
          </Typography>
          <IconButton
            onClick={selectNextPeriod}
            disabled={lastSelected}
            sx={{ p: 0 }}
          >
            <GoForwardIcon fontSize="small" />
          </IconButton>
        </Stack>
        <Box />
      </Stack>
      <Timeline
        groups={groups}
        items={items}
        defaultTimeStart={start}
        defaultTimeEnd={end}
        visibleTimeStart={start.getTime()}
        visibleTimeEnd={end.getTime()}
        canChangeGroup={false}
        canMove={false}
        canResize={false}
        stackItems
        sidebarWidth={0}
        lineHeight={132}
        itemHeightRatio={0.95}
        onItemSelect={onItemSelect}
        onItemClick={onItemClick}
        minZoom={zoom}
        maxZoom={zoom}
        itemRenderer={TimelineItemRenderer}
        resizeDetector={resizeDetector as any}
      >
        <TimelineMarkers>
          <CustomTodayMarker />
        </TimelineMarkers>
      </Timeline>

      {periods?.length === 0 && (
        <Button
          variant="primary"
          onClick={() => setShowCreate(true)}
          sx={{ mt: 2, alignSelf: "center" }}
        >
          Skapa ny schemaperiod
        </Button>
      )}

      <CreatePeriod
        open={showCreate}
        onClose={() => {
          setShowCreate(false);
          refresh();
        }}
        periodLengthWeeks={periodLength}
        defaultStartDate={DateTime.fromJSDate(lastEndDate).toFormat(
          "yyyy-MM-dd",
        )}
      />

      <DeleteModal
        show={showDelete}
        onHide={() => setToDelete(null)}
        deleteTitle="Radera schemaperiod?"
        deleteMessage={
          <>
            <Typography>
              Är du säker på att du vill radera schemaperiod{" "}
              <strong>vecka {toDelete?.name}</strong>? Alla schemakörningar för
              perioden kommer att gå förlorade.
            </Typography>
            <Typography>Denna åtgärd kan inte ångras.</Typography>
          </>
        }
        buttonText="Radera schemaperiod"
        onDeleteClick={async () => {
          await DeletePeriod(toDelete?.id || "");
          setToDelete(null);
          refresh();
          navigate("/periods");
        }}
      />
    </Stack>
  );
}
