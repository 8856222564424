import {
  CalendarMonth as ScheduleIcon,
  DateRange as VacationIcon,
  EventOutlined as PassprofilIcon,
  Groups as MembersIcon,
  HomeRounded as HomeIcon,
  LeaderboardRounded as BehovsprofilIcon,
  Person as AdminIcon,
  RoomPreferences as GroupSettingIcon,
  Tornado as SettingsIcon,
} from "@mui/icons-material";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import type { Me_me$data as Me } from "hooks/types";
import { connectionToArray } from "relay-help/arrays";
import type { Route } from "types/Route";
import { optimizationRulesUrl } from "utils/urls";

function IsSuperuser(user: Me) {
  const { flags } = useFeatureFlags();
  return flags.usePermissions || user.isSuperuser;
}

function IsGroupAdmin(user: Me) {
  const { flags } = useFeatureFlags();
  return (
    flags.usePermissions ||
    user.isSuperuser ||
    connectionToArray(user.groupAdminOf).length > 0
  );
}

function IsGroupAdminOf(user: Me, groupId: string) {
  const { flags } = useFeatureFlags();
  return (
    flags.usePermissions ||
    user.isSuperuser ||
    connectionToArray(user.groupAdminOf).some((g) => g.id === groupId)
  );
}

function IsTeamAdminOf(user: Me, teamId: string) {
  const { flags } = useFeatureFlags();
  return (
    flags.usePermissions ||
    user.isSuperuser ||
    connectionToArray(user.teamAdminOf).some((t) => t.id === teamId)
  );
}

export const homeRoute: Route = {
  icon: <HomeIcon />,
  label: "Hem",
  to: "/",
  end: true,
};
export const adminRoute: Route = {
  icon: <AdminIcon />,
  label: "Admin",
  to: "/admin",
  condition: IsSuperuser,
};
export const groupSettingRoute: Route = {
  icon: <GroupSettingIcon />,
  label: "Enhet",
  to: "/group-settings",
  end: true,
  condition: IsGroupAdmin,
};
export const memberRoute: Route = {
  icon: <MembersIcon />,
  label: "Personallista",
  to: "/members",
  condition: IsGroupAdmin,
};
export const settingRoute: Route = {
  icon: <SettingsIcon />,
  label: "Optimeringsregler",
  to: optimizationRulesUrl(),
  condition: (user, teamGroupId) => IsGroupAdminOf(user, teamGroupId!),
};
export const shiftRoute: Route = {
  icon: <PassprofilIcon />,
  label: "Passprofil",
  to: "/shifts",
  condition: (user, teamGroupId) => IsGroupAdminOf(user, teamGroupId!),
};
export const demandRoute: Route = {
  icon: <BehovsprofilIcon />,
  label: "Behovsprofil",
  to: "/demand",
  condition: (user, teamGroupId) => IsGroupAdminOf(user, teamGroupId!),
};
export const vacationRoute: Route = {
  icon: <VacationIcon />,
  label: "Händelser",
  to: "/vacation",
  condition: (user, teamGroupId, teamId) =>
    IsGroupAdminOf(user, teamGroupId!) || IsTeamAdminOf(user, teamId!),
};
export const periodRoute: Route = {
  icon: <ScheduleIcon />,
  label: "Lägg Schema",
  to: "/periods",
  condition: IsGroupAdmin,
};
