import type { ComponentProps } from "react";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import type { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import { useLocation } from "react-router-dom";
import type { StackProps } from "@mui/material";
import { Box, Container, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import {
  useCurrentTeamGroup,
  useGroupSettingsParams,
  useScrollToAnchor,
} from "hooks";
import {
  connectionToArray,
  ExtractNode,
  isNonNull,
  Unwrap,
} from "relay-help/arrays";

import DeleteModal from "components/common/DeleteModal";
import type { TocItem } from "components/group_settings";
import {
  Advanced,
  AdvancedCard,
  CompetencesCard,
  CompetencesList,
  Comprest,
  ComprestCard,
  CreateRuleGroupForm,
  CreateTeamForm,
  Definitions,
  DefinitionsCard,
  DemandProfile,
  DemandProfileCard,
  EditRuleGroupForm,
  EditTeamForm,
  ResourcePool,
  ResourcePoolCard,
  RuleGroupsCard,
  RuleGroupsList,
  SchedulePeriod,
  SchedulePeriodCard,
  TableOfContents,
  TeamsCard,
  TeamsList,
  Timebank,
  TimebankCard,
  useDeleteRuleGroup,
  useDeleteTeam,
} from "components/group_settings";
import { PageHeader, PageWrapper } from "components/layout/PageWrapper";
import { useSnackbar } from "components/Snackbar";

import type {
  GroupSettingsQuery as Query,
  GroupSettingsQuery$data as Data,
} from "./types";

const query = graphql`
  query GroupSettingsQuery($teamGroupId: ID!) {
    settingForTeamGroup(teamGroupId: $teamGroupId) {
      ...Definitions_fragment
      ...DemandProfile_fragment
      ...SchedulePeriod_fragment
      ...Timebank_fragment
      ...ResourcePool_fragment
      ...Comprest_fragment
      ...Advanced_fragment
    }
    teams(groupId: $teamGroupId) {
      ...TeamsList_teams
    }
    competences {
      ...CompetencesList_competences
    }
    ruleGroups(teamGroupId: $teamGroupId) {
      ...RuleGroupsList_ruleGroups
    }
    shiftTypes(teamGroupId: $teamGroupId) {
      id
      name
      start
      end
      shiftParts {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

type ValidItem =
  | typeof TeamsCard.id
  | typeof CompetencesCard.id
  | typeof RuleGroupsCard.id
  | typeof DemandProfileCard.id
  | typeof SchedulePeriodCard.id
  | typeof DefinitionsCard.id
  | typeof TimebankCard.id
  | typeof ResourcePoolCard.id
  | typeof ComprestCard.id
  | typeof AdvancedCard.id;

const GROUP_SETTING_ITEMS: TocItem<ValidItem>[] = [
  { id: TeamsCard.id, label: TeamsCard.title },
  { id: CompetencesCard.id, label: CompetencesCard.title },
  { id: RuleGroupsCard.id, label: RuleGroupsCard.title },
  { id: DemandProfileCard.id, label: DemandProfileCard.title },
  { id: SchedulePeriodCard.id, label: SchedulePeriodCard.title },
  { id: DefinitionsCard.id, label: DefinitionsCard.title },
  { id: TimebankCard.id, label: TimebankCard.title },
  { id: ResourcePoolCard.id, label: ResourcePoolCard.title },
  { id: ComprestCard.id, label: ComprestCard.title },
  { id: AdvancedCard.id, label: AdvancedCard.title },
];

type ContentProps = {
  queryRef: PreloadedQuery<Query>;
  onRuleGroupDelete: ComponentProps<typeof RuleGroupsList>["onDelete"];
  onTeamDelete: ComponentProps<typeof TeamsList>["onDelete"];
};

type SidePanelProps = StackProps;

type ShiftPart = ExtractNode<
  NonNullable<Unwrap<Data["shiftTypes"]>>["shiftParts"]
>;

function SidePanel(props: SidePanelProps) {
  const { hash } = useLocation();
  const selected = useMemo(() => (hash ? hash.slice(1) : ""), [hash]);

  return (
    <Stack {...props}>
      <Stack>
        <PageHeader title="Enhetsinställningar" />
      </Stack>
      <TableOfContents<ValidItem>
        items={GROUP_SETTING_ITEMS}
        selected={selected}
        gap={1}
      />
    </Stack>
  );
}

function Content({ queryRef, onRuleGroupDelete, onTeamDelete }: ContentProps) {
  const data = usePreloadedQuery<Query>(query, queryRef);
  const { setNewRuleGroup, setEditRuleGroup, setNewTeam, setEditTeam } =
    useGroupSettingsParams();
  const createTeam = useCallback(() => setNewTeam(true), [setNewTeam]);
  const createRuleGroup = useCallback(
    () => setNewRuleGroup(true),
    [setNewRuleGroup],
  );
  const setting = data.settingForTeamGroup;

  useScrollToAnchor();

  const shiftTypes = useMemo(
    () =>
      data.shiftTypes.filter(isNonNull).map((x) => ({
        ...x,
        shiftParts: connectionToArray<ShiftPart>(x.shiftParts),
      })),
    [data.shiftTypes],
  );

  return (
    <>
      <TeamsCard id={TeamsCard.id} openCreateTeam={createTeam}>
        {!!data.teams && (
          <TeamsList
            fragmentRef={data.teams}
            setEdit={setEditTeam}
            onDelete={onTeamDelete}
          />
        )}
      </TeamsCard>
      <CompetencesCard id={CompetencesCard.id}>
        {!!data.competences && (
          <CompetencesList fragmentRef={data.competences} />
        )}
      </CompetencesCard>
      <RuleGroupsCard id={RuleGroupsCard.id} openCreate={createRuleGroup}>
        {!!data.ruleGroups && data.ruleGroups[0] && (
          <RuleGroupsList
            fragmentRef={data.ruleGroups}
            setEdit={setEditRuleGroup}
            onDelete={onRuleGroupDelete}
          />
        )}
      </RuleGroupsCard>
      <DemandProfileCard id={DemandProfileCard.id}>
        {!!setting && <DemandProfile fragmentRef={setting} />}
      </DemandProfileCard>
      <SchedulePeriodCard id={SchedulePeriodCard.id}>
        {!!setting && <SchedulePeriod fragmentRef={setting} />}
      </SchedulePeriodCard>
      <DefinitionsCard id={DefinitionsCard.id}>
        {!!setting && <Definitions fragmentRef={setting} />}
      </DefinitionsCard>
      <TimebankCard id={TimebankCard.id}>
        {!!setting && (
          <Timebank fragmentRef={setting} shiftTypes={shiftTypes} />
        )}
      </TimebankCard>
      <ResourcePoolCard id={ResourcePoolCard.id}>
        {!!setting && <ResourcePool fragmentRef={setting} />}
      </ResourcePoolCard>
      <ComprestCard id={ComprestCard.id}>
        {!!setting && <Comprest fragmentRef={setting} />}
      </ComprestCard>
      <AdvancedCard id={AdvancedCard.id}>
        {!!setting && <Advanced fragmentRef={setting} />}
      </AdvancedCard>
    </>
  );
}

export function GroupSettings() {
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const teamGroup = useCurrentTeamGroup();
  const teamGroupId = teamGroup?.id ?? "";
  const [teamToDelete, setTeamToDelete] = useState<{
    id: string;
    name: string;
    members: [];
  } | null>(null);
  const [ruleGroupToDelete, setRuleGroupToDelete] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const {
    newRuleGroup,
    newTeam,
    editRuleGroup,
    editTeam,
    setNewRuleGroup,
    setNewTeam,
    setEditRuleGroup,
    setEditTeam,
  } = useGroupSettingsParams();
  const [deleteRuleGroup] = useDeleteRuleGroup();
  const [deleteTeam] = useDeleteTeam();
  const { addSnack } = useSnackbar();

  const closeCreateTeam = useCallback(() => setNewTeam(false), [setNewTeam]);
  const closeEditTeam = useCallback(() => setEditTeam(null), [setEditTeam]);
  const clearTeamToDelete = useCallback(
    () => setTeamToDelete(null),
    [setTeamToDelete],
  );
  const closeCreateRuleGroup = useCallback(
    () => setNewRuleGroup(false),
    [setNewRuleGroup],
  );
  const closeEditRuleGroup = useCallback(
    () => setEditRuleGroup(null),
    [setEditRuleGroup],
  );
  const clearRuleGroupToDelete = useCallback(
    () => setRuleGroupToDelete(null),
    [setRuleGroupToDelete],
  );

  useEffect(() => {
    if (!teamGroupId) return;
    loadQuery({ teamGroupId });
  }, [teamGroupId, loadQuery]);

  async function onDeleteRuleGroup(id?: string) {
    if (!id) return;

    await deleteRuleGroup({ variables: { id } })
      .then(clearRuleGroupToDelete)
      .catch(() => {
        addSnack({
          message: "Kunde inte radera regelgruppen",
          severity: "error",
        });
      });
  }

  async function onDeleteTeam(id?: string) {
    if (!id) return;

    await deleteTeam({ variables: { id } })
      .then(clearTeamToDelete)
      .catch(() => {
        addSnack({
          message: "Kunde inte radera avdelningen",
          severity: "error",
        });
      });
  }

  return (
    <>
      <PageWrapper>
        <Stack direction="row" gap={1}>
          <Box sx={{ width: 200, mr: 4 }}>
            <SidePanel gap={3} position="fixed" top={32} />
          </Box>
          <Stack component={Container} gap={5}>
            <Suspense fallback={<p>Laddar...</p>}>
              {!!queryRef && (
                <Content
                  queryRef={queryRef}
                  onRuleGroupDelete={setRuleGroupToDelete}
                  onTeamDelete={setTeamToDelete}
                />
              )}
            </Suspense>
          </Stack>
        </Stack>
      </PageWrapper>
      <CreateTeamForm
        open={newTeam}
        onClose={closeCreateTeam}
        initialValues={{ groupId: teamGroupId }}
      />
      {!!editTeam && (
        <EditTeamForm open={!!editTeam} onClose={closeEditTeam} id={editTeam} />
      )}
      {!!teamToDelete && (
        <DeleteModal
          show={!!teamToDelete}
          onHide={clearTeamToDelete}
          buttonText="Radera"
          deleteTitle="Radera avdelning?"
          deleteMessage={`Är du säker på att du vill radera avdelningen "${teamToDelete?.name}"?
            ${
              (teamToDelete?.members?.length ?? 0) > 0
                ? ` Den har ${teamToDelete.members.length} personer kopplade till sig. En person utan avdelning kommer inte att schemaläggas.`
                : ""
            }`}
          onDeleteClick={() => onDeleteTeam(teamToDelete?.id)}
        />
      )}
      <CreateRuleGroupForm
        open={newRuleGroup}
        onClose={closeCreateRuleGroup}
        teamGroupId={teamGroupId}
      />
      {!!editRuleGroup && (
        <EditRuleGroupForm
          open={!!editRuleGroup}
          onClose={closeEditRuleGroup}
          id={editRuleGroup}
        />
      )}
      {!!ruleGroupToDelete && (
        <DeleteModal
          show={!!ruleGroupToDelete}
          onHide={clearRuleGroupToDelete}
          buttonText="Radera"
          deleteTitle="Radera regelgrupp?"
          deleteMessage={`Är du säker på att du vill radera regelgruppen "${ruleGroupToDelete?.name}"?`}
          onDeleteClick={() => onDeleteRuleGroup(ruleGroupToDelete?.id)}
        />
      )}
    </>
  );
}
