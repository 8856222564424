import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import { connectionToArray } from "relay-help/arrays";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";
import { useSnackbar } from "components/Snackbar";

import { ResourcePoolForm } from "./forms";
import { useUpdateResourcePoolMutation } from "./mutations";
import type {
  ResourcePool_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

type Props = { fragmentRef: Key };

const fragment = graphql`
  fragment ResourcePool_fragment on SettingNode {
    id
    teamsExemptFromResourcePool {
      edges {
        node {
          id
        }
      }
    }
    group {
      teams {
        edges {
          node {
            id
            name
            color
          }
        }
      }
    }
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required("Får ej vara tomt"),
  teamsExemptFromResourcePool: yup
    .array()
    .of(yup.string().required())
    .required(),
});

export function ResourcePool({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateResourcePoolMutation();
  const { addSnack } = useSnackbar();
  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      teamsExemptFromResourcePool: connectionToArray(
        data.teamsExemptFromResourcePool,
      ).map((x) => x.id),
    }),
    [data],
  );

  const teams = useMemo(
    () =>
      connectionToArray(data.group.teams).map((t) => ({
        ...t,
        group: { id: "", name: "" },
      })),
    [data?.group?.teams],
  );

  const onSubmit = useCallback(
    async (input: FormValues) =>
      await commit({ variables: { input } }).catch(() => {
        addSnack({ severity: "error", message: "Uppdatering misslyckades" });
      }),
    [commit, addSnack],
  );
  const memoizedInitialValues = useMemo(
    () => initialValues,
    [initialValues.id],
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={memoizedInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <ResourcePoolForm teams={teams} />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
