import { useMemo } from "react";
import { Typography } from "@mui/material";
import { useRequireMe } from "hooks";

import { PageHeader, PageWrapper } from "components/layout/PageWrapper";

export function Home() {
  const me = useRequireMe().userData;
  const { fullName, email, username } = me ?? {};
  const user = useMemo(
    () => fullName || email || username,
    [fullName, email, username],
  );
  return (
    <PageWrapper header={<PageHeader title={`Hej ${user}!`} />}>
      <Typography variant="h4" fontWeight={400}>
        Välkommen till Konvojs plattform.
      </Typography>
    </PageWrapper>
  );
}
