import { CircularProgress, Stack } from "@mui/material";

import { Panel1 } from "components/setting/common";

function SettingBoxPlaceholder() {
  return <CircularProgress />;
}

function SettingLevel1Placeholder() {
  return (
    <Panel1 sx={{ justifyContent: "center" }}>
      <CircularProgress />
    </Panel1>
  );
}

export function SettingLevel3Placeholder() {
  return (
    <Stack p={2} width="100%" height="100%">
      <SettingBoxPlaceholder />
    </Stack>
  );
}

export function TeamGroupSettingsPlaceholder() {
  return (
    <Stack direction="row" flexGrow={2}>
      <SettingLevel3Placeholder />
    </Stack>
  );
}

export function SettingsPlaceholder() {
  return (
    <Stack direction="row" flexGrow={2}>
      <SettingLevel1Placeholder />
      <SettingLevel3Placeholder />
    </Stack>
  );
}
