import { useCallback } from "react";
import { Radio, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";

import DatePickerWithWeekNumbers from "components/schedule/DatePickerWithWeekNumbers";

type Props = {
  value: Date | null;
  onChange: (value: Date | null) => void;
};

export default function UntilDateSelect({ value, onChange }: Props) {
  function handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value === "until") {
      onChange(new Date());
    } else {
      onChange(null);
    }
  }

  const changeDate = useCallback(
    (inputDate: DateTime | null) => {
      if (inputDate) onChange(inputDate.toJSDate());
    },
    [onChange],
  );

  return (
    <Stack direction="row" sx={{ display: "flex", alignItems: "center" }}>
      <Radio value="until" checked={!!value} onChange={handleRadioChange} />
      <Typography sx={{ pr: 1 }}>Sista datum</Typography>
      <DatePickerWithWeekNumbers
        onChange={changeDate}
        value={
          value ? DateTime.fromJSDate(value) : DateTime.fromJSDate(new Date())
        }
        disabled={!value}
      />
    </Stack>
  );
}
