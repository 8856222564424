import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type FeatureFlags = {
  flags: Flags;
  updateFeatureFlag: (flag: keyof Flags, value: Flags[typeof flag]) => void;
};

type Flags = {
  useScheduleService: boolean;
  usePermissions: boolean;
};

const DEFAULT_FEATURE_FLAGS: Flags = {
  useScheduleService:
    process.env.NODE_ENV === "development" ||
    process.env.REACT_APP_DEVMODE === "true",
  usePermissions: false,
};

const FeatureFlagContext = createContext<FeatureFlags | null>(null);

export const useFeatureFlags = () => {
  const context = useContext<FeatureFlags | null>(FeatureFlagContext);

  if (context == null) {
    throw new Error("useFeatureFlags must be used within FeatureFlagProvider");
  }

  return context;
};

export function FeatureFlagProvider({ children }: PropsWithChildren) {
  const [flags, setFlags] = useState<Flags>(DEFAULT_FEATURE_FLAGS);

  const updateFeatureFlag = useCallback(
    (flag: keyof Flags, value: Flags[typeof flag]) =>
      setFlags((prevFlags) => ({
        ...prevFlags,
        [flag]: value,
      })),
    [setFlags],
  );

  const featureFlags: FeatureFlags = useMemo(
    () => ({ flags, updateFeatureFlag }),
    [flags, updateFeatureFlag],
  );

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  );
}
