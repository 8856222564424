import { useMemo } from "react";
import { useFragment } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import { Form as FormikForm, Formik } from "formik";
import * as yup from "yup";

import { AutoSubmit } from "components/setting/common";
import { useSnackbar } from "components/Snackbar";

import { ComprestForm } from "./forms";
import { useUpdateComprestMutation } from "./mutations";
import type {
  Comprest_fragment$key as Key,
  UpdateSettingInput as FormValues,
} from "./types";

type Props = {
  fragmentRef: Key;
};

const fragment = graphql`
  fragment Comprest_fragment on SettingNode {
    id
    comprestCanCutDaybreakBilagaj
    comprestCanCutDaybreakAbjour
  }
`;

const validationSchema = yup.object().shape({
  id: yup.string().required("Får ej vara tomt"),
  comprestCanCutDaybreakBilagaj: yup.boolean(),
  comprestCanCutDaybreakAbjour: yup.boolean(),
});

export function Comprest({ fragmentRef }: Props) {
  const data = useFragment<Key>(fragment, fragmentRef);
  const [commit] = useUpdateComprestMutation();
  const { addSnack } = useSnackbar();

  const initialValues = useMemo<FormValues>(
    () => ({
      id: data.id,
      comprestCanCutDaybreakBilagaj: data.comprestCanCutDaybreakBilagaj,
      comprestCanCutDaybreakAbjour: data.comprestCanCutDaybreakAbjour,
    }),
    [data],
  );

  async function onSubmit(input: FormValues) {
    await commit({ variables: { input } }).catch(() => {
      addSnack({ severity: "error", message: "Uppdatering misslyckades" });
    });
  }
  const memoizedInitialValues = useMemo(
    () => initialValues,
    [initialValues.id],
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={memoizedInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <FormikForm autoComplete="off">
        <AutoSubmit>
          <ComprestForm />
        </AutoSubmit>
      </FormikForm>
    </Formik>
  );
}
