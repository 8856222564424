import { Navigate, Outlet } from "react-router-dom";
import { useRequireMe } from "hooks";

type Props = { redirectTo: string; pname: string };

export function RequirePermission({ redirectTo, pname }: Props) {
  const { evaluatePermission } = useRequireMe();

  if (!evaluatePermission(pname, "READ")) {
    return <Navigate to={redirectTo} />;
  }

  return <Outlet />;
}
