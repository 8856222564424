import { Suspense, useEffect, useRef, useState } from "react";
import type { PreloadedQuery } from "react-relay";
import {
  fetchQuery,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useCurrentTeamGroup } from "hooks/CurrentTeamGroup";
import { DateTime } from "luxon";

import { PeriodPlaceholder } from "components/loading/pages";
import { PeriodTimeline } from "components/period/graphs/PeriodTimeline";

import { ScheduleRuns } from "./ScheduleRuns";
import { PeriodPageQuery as Query } from "./types";

const query = graphql`
  query PeriodPageQuery($groupId: ID!) {
    currentPeriod(groupId: $groupId) {
      id
    }
    periods(groupId: $groupId) {
      ...PeriodTimeline_fragment
    }
    settingForTeamGroup(teamGroupId: $groupId) {
      periodLengthWeeks
    }
  }
`;

type ContentProps = {
  periodId?: string;
  queryRef: PreloadedQuery<Query>;
};

function Content({ periodId, queryRef }: ContentProps) {
  const env = useRelayEnvironment();
  const data = usePreloadedQuery<Query>(query, queryRef);
  const periodLengthWeeks = data?.settingForTeamGroup?.periodLengthWeeks || 1;

  const selected = periodId || data.currentPeriod?.id || null;

  const [currentDate, setCurrentDate] = useState(
    DateTime.fromJSDate(new Date()),
  );
  const teamGroup = useCurrentTeamGroup();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);
  const teamGroupId = teamGroup?.id;

  async function refresh() {
    if (!teamGroupId) return;
    await fetchQuery(env, query, { groupId: teamGroupId }).toPromise();
  }

  // Hide old schedule runs when changing team group
  useEffect(() => {
    if (periodId && !isFirstRender.current) {
      navigate("/periods");
    }
    isFirstRender.current = false;
  }, [teamGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack gap={2}>
      <PeriodTimeline
        fragmentRef={data.periods}
        selected={selected}
        currentDateState={[currentDate, setCurrentDate]}
        periodLength={periodLengthWeeks}
        refresh={refresh}
      />
      {periodId && <ScheduleRuns periodId={periodId} />}
    </Stack>
  );
}

type TParams = { id?: string };

export function Period() {
  const { id } = useParams<TParams>();
  const [queryRef, loadQuery] = useQueryLoader<Query>(query);
  const teamGroup = useCurrentTeamGroup();
  const groupId = teamGroup?.id;

  useEffect(() => {
    if (!groupId) return;
    loadQuery({ groupId });
  }, [groupId, loadQuery]);

  return (
    <Suspense fallback={<PeriodPlaceholder />}>
      {!!queryRef && <Content periodId={id} queryRef={queryRef} />}
    </Suspense>
  );
}
