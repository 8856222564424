import { Suspense, useMemo, useState } from "react";
import { useFragment, useRelayEnvironment } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import {
  CalendarMonth as CalendarIcon,
  Check as CheckIcon,
  IosShare as ExportIcon,
} from "@mui/icons-material";
import { Box, Button, Menu, MenuItem, Stack, Tab, Tabs } from "@mui/material";
import { ReactComponent as MicrosoftExcelLogo } from "assets/microsoft-excel-logo.svg";
import graphql from "babel-plugin-relay/macro";
import { useRequireMe } from "hooks/Me";
import { SolutionStatus } from "types/Schedule";
import { BASE_URL } from "utils/constants";

import { ScheduleTabsPlaceholder } from "components/loading/pages";
import SetMasterSchedule from "components/schedule/mutations/SetMasterSchedule";

import type { ScheduleTabs_fragment$key as Key } from "./__generated__/ScheduleTabs_fragment.graphql";

const fragment = graphql`
  fragment ScheduleTabs_fragment on ScheduleNode {
    id
    isMaster
    solutionStatus
    period {
      id
    }
  }
`;

function ExportMenu({ scheduleId }: { scheduleId: string }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const excelUrl = `${BASE_URL}/downloads/schedule/${scheduleId}/excel`;

  return (
    <>
      <Button
        variant="outlined"
        endIcon={<ExportIcon />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        Exportera
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem>
          <a href={excelUrl} title="Ladda ner">
            <Button variant="selectOption">
              Exportera som Excel{" "}
              <MicrosoftExcelLogo
                style={{
                  height: "18px",
                  width: "18px",
                  marginTop: "-3px",
                  marginLeft: "5px",
                }}
              />
            </Button>
          </a>
        </MenuItem>
      </Menu>
    </>
  );
}

type RightSideButtonsProps = {
  fragmentRef: Key;
};

function RightSideButtons({ fragmentRef }: RightSideButtonsProps) {
  const environment = useRelayEnvironment();

  const { userData, fetchPermissions, evaluatePermission } = useRequireMe();
  const schedule = useFragment<Key>(fragment, fragmentRef);
  const isValidSchedule = useMemo(
    () =>
      schedule.solutionStatus === SolutionStatus.FEASIBLE ||
      schedule.solutionStatus === SolutionStatus.HARD_CONSTRAINT_COLLISION,
    [schedule.solutionStatus],
  );

  async function onSetMasterSchedule(setOrRemove: boolean) {
    try {
      await SetMasterSchedule(environment, {
        scheduleId: schedule.id,
        setOrRemove,
      });
    } catch (error) {
      fetchPermissions(userData.id);
    }

    /*
    await fetchQuery(environment, scheduleRunsTableQuery, {
      periodId: schedule.period.id,
    }).toPromise();
    */
  }

  return (
    <Stack id="buttons" direction="row" gap={1} alignItems="center">
      {evaluatePermission("Fastställ schema", "WRITE") &&
        (schedule.isMaster ? (
          <Button
            variant="primary"
            endIcon={<CheckIcon />}
            onClick={() => onSetMasterSchedule(false)}
            sx={{ width: 188 }}
          >
            Schemat är fastställt
          </Button>
        ) : isValidSchedule ? (
          <Button
            variant="primary"
            onClick={() => onSetMasterSchedule(true)}
            endIcon={<CalendarIcon />}
            sx={{ width: 188 }}
          >
            Fastställ schemat
          </Button>
        ) : null)}
      <ExportMenu scheduleId={schedule.id} />
    </Stack>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type TabType = {
  label: string;
  content: any;
  id: string;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    style: {
      textTransform: "capitalize" as const,
    },
  };
}

type TabProps = {
  tabs: TabType[];
  index?: number;
  fragmentRef: Key;
};

export function ScheduleTabs({ tabs, index, fragmentRef }: TabProps) {
  const navigate = useNavigate();
  const [value, setValue] = useState(index || 0);
  const schedule = useFragment<Key>(fragment, fragmentRef);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(`/schedules/${schedule.id}/${newValue}`);
  };

  const tabSx = {
    bgcolor: "white",
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderColor: "divider",
    borderStyle: "solid",
    borderWidth: 1,
    borderBottom: 0,
    marginRight: 0.4,
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Box>
          <Tabs value={value} onChange={handleChange}>
            {tabs.map((t: TabType, i: number) => (
              <Tab label={t.label} {...a11yProps(i)} key={i} sx={tabSx} />
            ))}
          </Tabs>
        </Box>
        <RightSideButtons fragmentRef={fragmentRef} />
      </Stack>
      <Suspense fallback={<ScheduleTabsPlaceholder />}>
        {tabs.map((t: TabType, i: number) => (
          <TabPanel value={value} index={i} key={i}>
            {t.content}
          </TabPanel>
        ))}
      </Suspense>
    </Box>
  );
}
